export const activities = {
  route: () => import('./Activities.vue'),
  propTypes: {
    boxId: {
      type: String,
      required: true,
      // default: '1344073',
    },
    websiteTemplate: {
      type: String,
      required: true,
      default: 'ALCDEV',
    },
    hideLocation: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    kmRadius: {
      type: Number,
      default: -1,
    },
  },
};
