<template>
  <div class="app">
    <header>
      <h1>Search Components Dev tool</h1>
    </header>
    <section class="dev-section">
      <label class="dev-label" for="component-dropdown">Component selector: </label>
      <select class="dev-select" name="component-dropdown" v-model="selectedComponent">
        <option v-for="name in componentList" :value="name" :key="name">
          {{ name }}
        </option>
      </select>

      <button class="dev-button" @click="mountUnmountComponent">
        {{ isComponentMounted ? 'Unmount' : 'Mount' }}
      </button>

      <label for="component-dropdown">Dev URL: </label>
      <select class="dev-select" name="component-dropdown" v-model="selectedDevUrl">
        <option v-for="url in devUrlList" :value="url" :key="url">
          {{ url }}
        </option>
      </select>
    </section>
    <section class="dev-section">
      <h4 class="props-title">Props</h4>
      <button class="dev-button toggle" @click="arePropsVisible = !arePropsVisible">
        {{ arePropsVisible ? 'Hide props' : 'Show props' }}
      </button>
    </section>
    <main id="main">
      <aside class="side-menu" v-if="selectedComponent">
        <div id="search-props" v-if="arePropsVisible">
          <div
            class="search-PropTypes"
            v-for="(option, index) in {
              ...Object.keys(componentExports[selectedComponent].propTypes),
            }"
            :key="index"
          >
            <label class="dev-label" for="options-dropdown">{{ option }} </label>
            <input
              v-if="componentExports[selectedComponent].propTypes[option].type === Number"
              type="number"
              :value="getCustomModelValue(option)"
              @input="setCustomModelValue(option, $event)"
              class="dev-input"
            />
            <input
              v-else-if="componentExports[selectedComponent].propTypes[option].type === Boolean"
              type="checkbox"
              :checked="getCustomModelValue(option)"
              @change="setCustomModelValue(option, $event)"
              class="dev-input"
            />
            <textarea
              v-else
              class="dev-textarea"
              :class="option.type"
              :value="getCustomModelValue(option)"
              @input="setCustomModelValue(option, $event)"
            />
          </div>
        </div>
      </aside>
      <sbx-sc v-if="isComponentMounted" :name="selectedComponent" :options="selectedOptions" />
    </main>
    <footer>
      <div>
        <h4>Generate Search component Tag</h4>
        <button class="dev-button" @click="generateTag()">Generate Tag</button>
        <button class="dev-button" @click="writeTag()">Clear Tag</button>
        <code id="generateTagDiv"></code>
      </div>
    </footer>
  </div>
</template>

<script>
import SbxSc from '../SbxSC_Wrapper.vue';
import componentExports from '@/components/componentExports';
import { brandingImporter } from '@sbx/prec-shared-utils';
import { developmentUrls } from '@sbx/prec-shared-utils/utils/localization/developmentUrls';

export default {
  components: { SbxSc },
  name: 'Index',
  data() {
    return {
      componentExports,
      selectedComponent: '',
      selectedOptions: {},
      selectedDevUrl: sessionStorage.getItem('baseUrlOveride') || 'https://dev.smartbox.com/fr/',
      isComponentMounted: false,
      tagControlButtonLabel: 'Generate',
      initialUnmountedComponents: [],
      arePropsVisible: true,
    };
  },
  mounted() {
    // In live sites, dataLayer array will be created by the adobe plugin as a global.
    // For SC we should instantiate it here.
    window.dataLayer = [];
    // Set this to true so the index.html page knows we are using the dev tool
    window.dispatchEvent(new CustomEvent('sbx_development:set', { detail: true }));
  },
  computed: {
    componentList() {
      return Object.keys(this.componentExports);
    },
    devUrlList() {
      return developmentUrls;
    },
  },
  watch: {
    selectedComponent() {
      if (!this.selectedComponent || this.selectedComponent === '-') {
        this.selectedOptions = {};
      } else {
        const componentExport = this.componentExports[this.selectedComponent];

        this.isComponentMounted = !this.initialUnmountedComponents.includes(this.selectedComponent);

        const options = {};
        for (const [key, value] of Object.entries(componentExport.propTypes)) {
          if (value.type === Boolean) {
            options[key] = value.default || false;
          } else {
            options[key] = value.default || '';
          }
        }
        this.selectedOptions = options;
      }
    },
    selectedDevUrl() {
      this.isComponentMounted = false;
      sessionStorage.setItem('baseUrlOveride', this.selectedDevUrl);
      this.brandingRoot = brandingImporter();
      setTimeout(() => {
        this.isComponentMounted = true;
      }, 300);
    },
  },
  methods: {
    generateTag() {
      const wrap = `
        <sbx-sc
          name="${this.selectedComponent}"
          options='${JSON.stringify(this.selectedOptions, null, 2)}'
        ></sbx-sc>`;
      this.writeTag(wrap);
    },
    writeTag(wrap = '') {
      const tag = document.getElementById('generateTagDiv');
      let outerHTML = '';
      if (wrap !== '') {
        outerHTML = wrap;
        this.tagControlButtonLabel = 'Clear';
      }
      if (tag != null) {
        tag.innerText = outerHTML;
      }
    },
    mountUnmountComponent() {
      this.isComponentMounted = !this.isComponentMounted;
    },
    setCustomModelValue(option, { target: { value, checked } }) {
      const { type } = this.componentExports[this.selectedComponent].propTypes[option];
      if (type === Array || type === Object) {
        this.selectedOptions[option] = JSON.parse(value);
        return;
      }
      if (type === Number) {
        this.selectedOptions[option] = Number(value);
        return;
      }

      if (type === Boolean) {
        this.selectedOptions[option] = checked;
        return;
      }

      this.selectedOptions[option] = value;
    },
    getCustomModelValue(option) {
      const { type } = this.componentExports[this.selectedComponent].propTypes[option];
      return type === Array || type === Object ? JSON.stringify(this.selectedOptions[option]) : this.selectedOptions[option];
    },
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

header {
  text-align: center;
  img {
    max-width: 350px;
  }
  h1 {
    color: #7b6d69;
    text-decoration: underline;
    margin: 8px;
  }
}

.app {
  padding: 10px;
  font-family: 'Montserrat', sans-serif;

  #main {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 20px;

    .side-menu {
      flex: 1;
      background-color: #eee;
    }
  }
}

#search-props {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dev-label {
  display: block;
}

.dev-input,
.dev-textarea,
.dev-button,
.dev-select {
  padding: 6px;
  border: 1px solid #7b6d69;
  border-radius: 10px;
  background-color: white;
  margin: 0 6px;
  outline: #8f4b3c;
}

.dev-button {
  cursor: pointer;
  border: 1px solid #eb5f40;
  background-color: #eb5f40;
  color: white;
  outline: #eb5f40;

  &:hover {
    background-color: #d36851;
  }
}

.dev-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.props-title {
  margin: 6px 0;
}
</style>
