export const landingPage = {
  route: () => import('./LandingPage.vue'),
  propTypes: {
    searchBarPlaceholder: {
      type: String,
    },
    hiddenFilters: {
      type: Array,
      default: [
        'facilities',
        'number_nights',
        'includes',
        'overnight_stay',
        'experiences',
        'type',
        'entertainment',
        'active_tours',
        'team_activities',
        'sports_and_activities',
        'sport_and_adventure',
        'universe',
        'category',
        'car_brand',
        'number_of_laps',
        'number_of_cars',
        'race_tracks',
        'region',
        'department',
        'occasions',
        'for_whom',
        'gift_ideas_by_age',
        'gift_ideas_for_anniversary',
        'new_products',
        'french_regions',
        'countries',
        'french_cities',
        'christmas',
        'unusual',
        'discount',
        'collection_type',
        'product_format',
        'massage_length',
        'european_cities',
        'air_adventures',
        'type_of_ticket',
        'days_out',
        'food_and_drink',
        'type_of_cuisine',
        'establishment',
        'treatment_type',
        'wellness_facilities',
        'activity_count',
        'sub_region',
        'country',
        'price',
        'number_people',
        'price_per_night',
        'destinations',
      ],
    },
    filterOrder: {
      type: Array,
      default: ['search_bar', 'purchase_price', 'person_count', 'number_of_nights', 'accommodation', 'available_options', 'theme'],
    },
    preselectedOptions: {
      type: Object,
      default: {},
    },
    lockedOptions: {
      type: Object,
      default: {},
    },
    visibleOptions: {
      type: Object,
      default: {},
    },
    maxFilters: {
      type: Number,
      default: 15,
    },
    showSearchBar: {
      type: Boolean,
      default: true,
    },
    priceRangeFilter: {
      type: String,
      default: '',
    },
  },
};
