import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueSanitize from 'vue-sanitize';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: false, // Load the API manually from the ActivityMapView component
});

const sanitizeOptions = {
  allowedTags: ['i', 'b', 'strong', 'div', 'span'],
  allowedAttributes: {
    '*': ['style'],
  },
};

Vue.use(VueI18n);
Vue.use(VueSanitize, sanitizeOptions);

export const i18n = new VueI18n({
  messages: {
    en: { ...require('@sbx/prec-shared-utils/locale/en.json'), ...require('../assets/locale/en.json') },
    fr: { ...require('@sbx/prec-shared-utils/locale/fr.json'), ...require('../assets/locale/fr.json') },
    es: { ...require('@sbx/prec-shared-utils/locale/es.json'), ...require('../assets/locale/es.json') },
    it: { ...require('@sbx/prec-shared-utils/locale/it.json'), ...require('../assets/locale/it.json') },
    de: { ...require('@sbx/prec-shared-utils/locale/de.json'), ...require('../assets/locale/de.json') },
    nl: { ...require('@sbx/prec-shared-utils/locale/nl.json'), ...require('../assets/locale/nl.json') },
    da: { ...require('@sbx/prec-shared-utils/locale/da.json'), ...require('../assets/locale/da.json') },
    sv: { ...require('@sbx/prec-shared-utils/locale/sv.json'), ...require('../assets/locale/sv.json') },

    sbx_fr_fr: { ...require('../assets/locale/brand-country-language-overwrite/sbx_fr_fr.json') },
    sbx_ch_fr: { ...require('../assets/locale/brand-country-language-overwrite/sbx_ch_fr.json') },
    dak_fr_fr: { ...require('../assets/locale/brand-country-language-overwrite/dak_fr_fr.json') },
    sbx_es_es: { ...require('../assets/locale/brand-country-language-overwrite/sbx_es_es.json') },
    dak_es_es: { ...require('../assets/locale/brand-country-language-overwrite/dak_es_es.json') },
    sbx_it_it: { ...require('../assets/locale/brand-country-language-overwrite/sbx_it_it.json') },
    emo_it_it: { ...require('../assets/locale/brand-country-language-overwrite/emo_it_it.json') },
    sbx_ch_it: { ...require('../assets/locale/brand-country-language-overwrite/sbx_ch_it.json') },
    sbx_ie_en: { ...require('../assets/locale/brand-country-language-overwrite/sbx_ie_en.json') },
    sbx_ch_de: { ...require('../assets/locale/brand-country-language-overwrite/sbx_ch_de.json') },
    bon_nl_nl: { ...require('../assets/locale/brand-country-language-overwrite/bon_nl_nl.json') },
    bon_be_nl: { ...require('../assets/locale/brand-country-language-overwrite/bon_be_nl.json') },
    bon_be_fr: { ...require('../assets/locale/brand-country-language-overwrite/bon_be_fr.json') },
    sbx_dk_da: { ...require('../assets/locale/brand-country-language-overwrite/sbx_dk_da.json') },
    sbx_se_sv: { ...require('../assets/locale/brand-country-language-overwrite/sbx_se_sv.json') },
    cbx_be_nl: { ...require('../assets/locale/brand-country-language-overwrite/cbx_be_nl.json') },
    cbx_be_fr: { ...require('../assets/locale/brand-country-language-overwrite/cbx_be_fr.json') },
    lav_es_es: { ...require('../assets/locale/brand-country-language-overwrite/lav_es_es.json') },
    sbx_de_de: { ...require('../assets/locale/brand-country-language-overwrite/sbx_de_de.json') },
  },
  fallbackLocale: {
    sbx_fr_fr: ['fr'],
    sbx_ch_fr: ['fr'],
    dak_fr_fr: ['fr'],
    sbx_es_es: ['es'],
    dak_es_es: ['es'],
    sbx_it_it: ['it'],
    emo_it_it: ['it'],
    sbx_ch_it: ['it'],
    sbx_ie_en: ['en'],
    sbx_ch_de: ['de'],
    sbx_de_de: ['de'],
    bon_nl_nl: ['nl'],
    bon_be_nl: ['nl'],
    bon_be_fr: ['fr'],
    sbx_dk_da: ['da'],
    sbx_se_sv: ['sv'],
    cbx_be_nl: ['nl'],
    cbx_be_fr: ['fr'],
    lav_es_es: ['es'],
  },
  silentTranslationWarn: true,
});

const fallbackPlugin = {
  install() {
    Vue.prototype.$_fb = (customised = '', fallback) => (customised !== '' ? customised : fallback);
  },
};
Vue.use(fallbackPlugin);
