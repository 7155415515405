export const screenSizesMixin = {
  data() {
    return {
      screenSize: '',
    };
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.screenSize = screenWidthIdentifer(window.innerWidth);
    },
  },
};

export function screenWidthIdentifer(width) {
  if (width < 640) {
    return screenSizes.mobile;
  }
  if (width >= 640 && width < 1280) {
    return screenSizes.contained;
  }
  if (width >= 1280 && width < 1600) {
    return screenSizes.wide;
  }
  if (width >= 1600) {
    return screenSizes.fullWidth;
  }
}

export const screenSizes = {
  mobile: 'mobile',
  contained: 'contained',
  wide: 'wide',
  fullWidth: 'full-width',
  all: 'all',
};
