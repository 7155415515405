import { dummyBrochure } from './DummyBrochure';
import { dummyProductList } from './DummyProductList';
import { autocomplete } from './Autocomplete';
import { activities } from './Activities';
import { landingPage } from './LandingPage';

export default {
  dummyBrochure,
  dummyProductList,
  autocomplete,
  activities,
  landingPage
};
