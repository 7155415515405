export const autocomplete = {
  route: () => import('./Autocomplete.vue'),
  propTypes: {
    placeholder: {
      type: String,
      required: true,
    },
    submitAction: {
      type: String,
      required: true,
    },
    maxPopularSearchesItems: {
      type: Number,
      default: 5,
      validator: positiveValue,
    },
    maxOurSelectionItems: {
      type: Number,
      default: 4,
      validator: positiveValue,
    },
    maxLocationItems: {
      type: Number,
      default: 4,
      validator: positiveValue,
    },
    maxProductItems: {
      type: Number,
      default: 4,
      validator: positiveValue,
    },
  },
};

function positiveValue(value) {
  return !value || value >= 0;
}
