import Vue from 'vue';
import SbxSc from './pages/SbxSC_Wrapper.vue';
import Index from './pages/SCTool/Index.vue';
import wrap from '@vue/web-component-wrapper';

if (process.env.NODE_ENV !== 'development') {
  /* eslint-disable */
  let wrappedElement;
  wrappedElement = wrap(Vue, SbxSc);
  window.customElements.define('sbx-sc', wrappedElement);
} else {
  //inject wrapper development tool
  new Vue({
    render: h => h(Index),
  }).$mount('#app');
}
